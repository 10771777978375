import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
    props: {
        heading: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    setup() {
        const opened = ref(false);
        return {
            opened
        };
    }
});
