import { computed, defineComponent } from '@vue/composition-api';
import { getBookingState } from '@/Booking/utils/bookings';
import useUserRoles from '@/shared/composables/useUserRoles';
import { BookingStates } from '@/Booking/booking.const';
import BookingStatusLabel from '@/Booking/components/BookingStatusLabel.vue';
export default defineComponent({
    components: {
        BookingStatusLabel
    },
    props: {
        booking: { type: Object, default: null },
        tab: {
            type: Number,
            default: 1
        }
    },
    setup(props, context) {
        const { root } = context;
        const roles = useUserRoles();
        const relevantBooking = computed(() => {
            return props.tab === 1 ? props.booking : props.booking.origin;
        });
        const state = computed(() => {
            const bookingState = getBookingState(relevantBooking.value);
            return (root.$te(`booking_summary.status.state.${bookingState?.toLowerCase()}`) && bookingState);
        });
        const user = computed(() => {
            const bookerFirstName = relevantBooking.value?.order?.booker.first_name || '';
            const bookerLastName = relevantBooking.value?.order?.booker.last_name || '';
            const bookerFullName = bookerFirstName.concat(' ', bookerLastName);
            const vendorFirstName = relevantBooking.value?.order?.vendor.first_name || '';
            const vendorLastName = relevantBooking.value?.order?.vendor.last_name || '';
            const vendorFullName = vendorFirstName.concat(' ', vendorLastName);
            return {
                [BookingStates.CANCELLED_BY_HOST]: vendorFullName,
                [BookingStates.CANCELLED_BY_CUSTOMER]: bookerFullName,
                [BookingStates.DECLINED]: vendorFullName
            };
        });
        return {
            relevantBooking,
            roles,
            user,
            state
        };
    }
});
