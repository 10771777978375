import { defineComponent, computed } from '@vue/composition-api';
import Popover from '@/components/Popover.vue';
import ListItem from '@/uikit/ListItem.vue';
export default defineComponent({
    components: {
        Popover,
        ListItem
    },
    props: {
        order: {
            type: Array,
            default: () => []
        },
        vat21: {
            type: Number,
            default: 0
        },
        vat9: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const vatBreakdown = computed(() => {
            function vatCalculation(arr) {
                return arr.reduce((a, b) => a + (b.vat ? b.vat : 0), 0);
            }
            if (props.order.length > 0) {
                const vat21items = props.order.filter(el => el.vat_rate === 21);
                const vat9items = props.order.filter(el => el.vat_rate === 9);
                return {
                    vat21: vatCalculation(vat21items),
                    vat9: vatCalculation(vat9items)
                };
            }
            else {
                return {
                    vat21: props.vat21,
                    vat9: props.vat9
                };
            }
        });
        return {
            vatBreakdown
        };
    }
});
