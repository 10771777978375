/**
 * Remove some keys from the object for GTM events.
 * @param {object} obj Any object to send to GTM (e.g. a page view event).
 * @returns {object} The object without the keys.
 */
export const cleanObjectForGTM = (obj) => {
    try {
        const _obj = obj;
        // Remove keys for GDPR
        delete _obj['meeting_name'];
        delete _obj['meeting_description'];
        delete _obj['meeting_description'];
        delete _obj.order.customer;
        delete _obj.order.order_meta;
        delete _obj.order.host;
        return _obj;
    }
    catch (error) {
        return {};
    }
};
