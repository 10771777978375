import { computed, defineComponent, watch, onUnmounted } from '@vue/composition-api';
import { OrderStateType, usePaymentBookingSubscribeSubscription } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            default: null
        },
        value: {
            type: String,
            default: null
        }
    },
    setup(props, context) {
        const { emit } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const { result, stop } = usePaymentBookingSubscribeSubscription(() => ({
            bookingId: props.booking.booking_id,
            accountId: user.value?.account_id
        }), () => ({
            enabled: Boolean(props.booking.booking_id && user.value?.account_id)
        }));
        const status = computed(() => {
            emit('input', result?.value?.wait_for_booking_status?.order.order_state);
            return result?.value?.wait_for_booking_status?.order.order_state;
        });
        watch(result, (bookingCurr, bookingPrev) => {
            if (bookingCurr?.wait_for_booking_status?.booking_status &&
                bookingCurr?.wait_for_booking_status?.booking_status !==
                    bookingPrev?.wait_for_booking_status?.booking_status) {
                emit('onStatusChanged');
            }
        }, { immediate: true });
        onUnmounted(() => {
            stop();
        });
        return {
            status,
            OrderStateType
        };
    }
});
